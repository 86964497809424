import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/login/ssoCallBack',
    name: 'LoginSsoCallBack',
    component: () => import('@/views/authentication/LoginSsoCallBack.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/logout/ssoCallBack',
    name: 'LogoutSsoCallBack',
    component: () => import('@/views/authentication/LogoutSsoCallBack.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
  {
    path: '/auth/notAuthorized',
    name: 'notAuthorized',
    component: () => import('@/views/authorization/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
  {
    path: '/auth/forgotPassword',
    name: 'forgotPassword',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
  {
    path: '/auth/verifyOTP',
    name: 'verifyOTP',
    component: () => import('@/views/authentication/VerifyOTP.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
  {
    path: '/auth/resetPassword',
    name: 'resetPassword',
    component: () => import('@/views/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
  {
    path: '/authorization/permission',
    name: 'permission',
    component: () => import('@/views/authorization/permission/Permission.vue'),
    meta: {
      pageTitle: 'Danh sách Quyền',
      breadcrumb: [
        {
          text: 'Phân quyền',
          active: false,
        },
        {
          text: 'Danh sách Quyền',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PERMISSION,
    },
  },
  {
    path: '/authorization/role',
    name: 'role',
    component: () => import('@/views/authorization/role/Role.vue'),
    meta: {
      pageTitle: 'Danh sách Vai trò',
      breadcrumb: [
        {
          text: 'Phân quyền',
          active: false,
        },
        {
          text: 'Danh sách Vai trò',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ROLE,
    },
  },
  {
    path: '/authorization/menu',
    name: 'authMenu',
    component: () => import('@/views/authorization/menu/Menu.vue'),
    meta: {
      pageTitle: 'Danh sách Menu',
      breadcrumb: [
        {
          text: 'Phân quyền',
          active: false,
        },
        {
          text: 'Danh sách Menu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.MENU,
    },
  },
  {
    path: '/authorization/account',
    name: 'authAccount',
    component: () => import('@/views/authorization/account/Account.vue'),
    meta: {
      pageTitle: 'Danh sách Tài khoản',
      breadcrumb: [
        {
          text: 'Phân quyền',
          active: true,
        },
        {
          text: 'Danh sách Tài khoản',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ACCOUNT,
    },
  },
  {
    path: '/authorization/resource',
    name: 'authResource',
    component: () => import('@/views/authorization/resource/Resource.vue'),
    meta: {
      pageTitle: 'Danh sách Đối tượng',
      breadcrumb: [
        {
          text: 'Phân quyền',
          active: false,
        },
        {
          text: 'Danh sách Đối tượng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.RESOURCE,
    },
  },
  {
    path: '/authorization/roles/:id/rolePermissionResource',
    name: 'authRolePermissionResource',
    component: () => import('@/views/authorization/role-permission-resource/RolePermissionResource.vue'),
    meta: {
      pageTitle: 'Phân quyền cho vai trò',
      breadcrumb: [
        {
          text: 'Phân quyền',
          active: false,
        },
        {
          text: 'Vai trò',
          active: false,
        },
        {
          text: 'Quyền và đối tượng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: 'ROLE_PERMISSION_RESOURCE',
    },
  },
  {
    path: '/change_password',
    name: 'changePassword',
    component: () => import('@/views/changePassword/Index.vue'),
    meta: {
      pageTitle: 'Thay đổi mật khẩu',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Thay đổi mật khẩu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: 'CHANGE_PASSWORD',
    },
  },
  {
    path: '/supervisor',
    name: 'grantSupervisor',
    component: () => import('@/views/authorization/supervisor/Supervisor.vue'),
    meta: {
      pageTitle: 'Phân vai giám thị',
      breadcrumb: [
        {
          text: 'Phân quyền',
          active: false,
        },
        {
          text: 'Phân vai giám thị',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRANT_SUPERVISOR,
    },
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/privacy-policy/PrivacyPolicy.vue'),
    meta: {
      layout: 'full',
      resource: 'AUTH',
    },
  },
]
